import {
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';

const avenueTheme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: '#00B8C7',
      contrastText: '#efeff0',
    },
    secondary: {
      main: '#292e3b',
      contrastText: '#efeff0',
    },
    thirdly: {
      main: '#ECECE6',
      contrastText: '#efeff0',
    },
    background: { default: '#fff' },
    outline: {
      main: '#D8D8DA',
      contrastText: '#FFFFFF',
    },
    artic: {
      main: '#2e3232',
      contrastText: '#e5f8f9',
    },
  },
  shape: { borderRadius: 2 },
  typography: { fontFamily: 'Arial' },
}));

export default avenueTheme;
