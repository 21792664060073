// tailwindcss
import { loadableReady } from '@loadable/component';
import ReactDOM from 'react-dom';
import './src/styles/global.css';

export const replaceHydrateFunction = () => {
    return (element, container, callback) => {
        loadableReady(() => {
            ReactDOM.render(element, container, callback);
        });
    };
};

// Updating Gatsby's default scroll behavior
export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    if (location.pathname === '/') {
        window.scrollTo(0, 0);
        return false; // false to prevent Gatsby's default scroll behavior
    }

    const savedPosition = getSavedScrollPosition(location);
    window.scrollTo(...(savedPosition || [0, 0]));

    return false; // false to prevent Gatsby's default scroll behavior
};
