import * as React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider, StylesProvider} from '@mui/styles';
import avenueTheme from './avenue-theme';

export default function TopLayout(props) {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={avenueTheme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </StylesProvider>
  );
}

TopLayout.propTypes = { children: PropTypes.node };
TopLayout.defaultProps = { children: null };
